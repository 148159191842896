import { DefaultTheme } from 'styled-components'

export const breakpointValues = {
  toTinyScreen: 400,
  toSmallScreen: 600,
  toMediumScreen: 800,
  toNormalScreen: 1000,
  toLargeScreen: 1200,
  toXLargeScreen: 1450,
  fromTinyScreen: 401,
  fromSmallScreen: 601,
  fromMediumScreen: 801,
  fromNormalScreen: 1001,
  fromLargeScreen: 1201,
  fromXLargeScreen: 1451,
}

const theme: DefaultTheme = {
  colours: {
    primary: '#27150F',
    primaryTint1: '#B89061',
    secondary: '#EFE7DD',
    tertiary: '#B89061',
    light: '#f9f3ee',
    lightTint1: '#a57d4e',
    lightTint2: '#b89061',
    lightTint3: '#e1d2c4',
    lightTint4: '#F5F5F5',
    lightOverlay1: 'rgba(255, 255, 255, 0.4)',
    lightOverlay2: 'rgba(255, 255, 255, 0.2)',
    dark: '#000000',
    darkTint1: '#383838',
    darkTint1Overlay: 'rgba(56, 56, 56, 0.9)',
    darkOverlay1: 'rgba(0, 0, 0, 0.6)',
    darkOverlay2: 'rgba(0, 0, 0, 0.2)',
    warning: 'rgba(199,0,0,1)',
    info: 'rgba(0,116,205,1)',
    success: 'rgba(25,138,0,1)',
    experiencesCategory: '#D500F9',
  },
  font: {
    fontFamilyDefault: "'AdelleSans', Tahoma, Arial, Helvetica, sans-serif",
    fontFamilyAlt: "'Copernicus', serif",
    fontSizeSmall: '1.2rem',
    fontSizeMid: '1.3rem',
    fontSizeLarge: '1.5rem',
    fontSizeBodyMobile: '1.6rem',
    fontSizeBodyDefault: '1.8rem',
    fontSizeBodyLarge: '2.2rem',
    fontSizeDesktop: '1.4rem',
    fontSizeNavigationMobile: '1.8rem',
    fontSizeLargeMobile: '2.2rem',
    fontSizeLargeTablet: '3.2rem',
    fontSizeLargeDesktop: '3.8rem',
    fontWeightLight: 300,
    fontWeightNormal: 400,
    fontWeightBold: 600,
    fontWeightBlack: 900,
    fontLetterSpacingNav: '0.13rem',
  },
  layout: {
    maxWidth: '166rem',
    headerHeightDesktop: '8rem',
    headerHeightTablet: '6rem',
    headerHeightMobile: '6rem',
    columnWidth: '14.2rem',
    gutterWidth: '2rem',
  },
  animation: {
    defaultLength: '0.2s',
    longLength: '1s',
  },
  padding: {
    mobileDefault: '2rem',
    tabletDefault: '5rem',
    screenDefault: '6rem',
    largeScreenDefault: '11rem',
  },
  breakpoints: {
    toTinyScreen: `(max-width: ${breakpointValues.toTinyScreen}px)`,
    toSmallScreen: `(max-width: ${breakpointValues.toSmallScreen}px)`,
    toMediumScreen: `(max-width: ${breakpointValues.toMediumScreen}px)`,
    toNormalScreen: `(max-width: ${breakpointValues.toNormalScreen}px)`,
    toLargeScreen: `(max-width: ${breakpointValues.toLargeScreen}px)`,
    toXLargeScreen: `(max-width: ${breakpointValues.toXLargeScreen}px)`,
    fromTinyScreen: `(min-width: ${breakpointValues.fromTinyScreen}px)`,
    fromSmallScreen: `(min-width: ${breakpointValues.fromSmallScreen}px)`,
    fromMediumScreen: `(min-width: ${breakpointValues.fromMediumScreen}px)`,
    fromNormalScreen: `(min-width: ${breakpointValues.fromNormalScreen}px)`,
    fromLargeScreen: `(min-width: ${breakpointValues.fromLargeScreen}px)`,
    fromXLargeScreen: `(min-width: ${breakpointValues.fromXLargeScreen}px)`,
  },
  icon: {
    defaultSize: '2.4rem',
    smallSize: '1.3rem',
    mediumSize: '1.8rem',
    largeSize: '3rem',
  },
}

export default theme
