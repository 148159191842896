import React, { useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'

const Body = styled.div<{ expanded: boolean }>`
  ${({ expanded, theme }): CSSProp => css`
    transition: all ${theme.animation.defaultLength} ease;
    max-height: ${expanded ? '500px' : 0};
    opacity: ${expanded ? 1 : 0};
    overflow: hidden;
  `}
`

const Button = styled.button<{ expanded: boolean }>`
  ${({ expanded, theme }): CSSProp => css`
    outline: none;
    cursor: pointer;
    background: none;
    border: none;
    svg {
      transition: all ${theme.animation.defaultLength} ease;
      height: 0.6rem;
      transform: ${expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
  `}
`

type CollapseProps = {
  button: React.ReactNode
  children: React.ReactNode
}

const Collapse = ({ button, children }: CollapseProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false)
  return (
    <>
      <Button
        expanded={expanded}
        className="toggle"
        onClick={(): void => setExpanded(!expanded)}
      >
        {button}
      </Button>
      <Body className="collapse-body" expanded={expanded}>
        {children}
      </Body>
    </>
  )
}

export default Collapse
