import React from 'react'
import ReactPlayer from 'react-player/youtube'
import styled, { css, CSSProp } from 'styled-components'

const StyledVideoBlock = styled.div`
  ${({ theme }): CSSProp => css`
    position: relative;
    padding-top: 56.25%;
    margin: 5rem -2rem;

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      margin: 5.5rem -7rem;
      padding-top: 68.25%;
    }
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      margin: 7rem 0;
      padding-top: 56.25%;
    }
    @media only screen and (min-width: 1520px) {
      margin: 7rem -16rem;
      padding-top: 85%;
    }

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  `}
`

const VideoBlock = ({ url }: { url: string }): JSX.Element => (
  <StyledVideoBlock>
    <ReactPlayer
      className="react-player"
      url={url}
      width="100%"
      height="100%"
    />
  </StyledVideoBlock>
)

export default VideoBlock
