import { useLocation } from '@reach/router' // this helps tracking the location
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import { Helmet } from 'react-helmet'

type SeoPropTypes = {
  lang?: string
  imageUrl?: string
  title: string
  description?: string
  meta?: any
  url?: string
  postTitle?: string
}

const Seo = (props: SeoPropTypes): JSX.Element => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            ogDescription
            ogImage
            author
            fbAppId
          }
        }
      }
    `
  )

  const {
    lang = useIntl().locale,
    title,
    description = site.siteMetadata.description,
    meta = [],
    imageUrl = site.siteMetadata.ogImage,
    postTitle,
    url,
  } = props

  const ogDescription = description || site.siteMetadata.ogDescription

  const location = useLocation()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${postTitle || site.siteMetadata.title}`}
      meta={[
        // TODO: Add SEO Keyword support
        // {
        //   name: 'keywords',
        //   content: tags,
        // },
        {
          name: 'description',
          content: ogDescription,
        },
        {
          name: 'region',
          content: process.env.GATSBY_BUILD_LOCALE,
        },
        {
          property: 'og:title',
          content: `${title} | ${postTitle || site.siteMetadata.title}`,
        },
        {
          property: 'og:description',
          content: ogDescription,
        },
        {
          property: 'fb:app_id',
          content: site.siteMetadata.fbAppId,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: `${title} | ${postTitle || site.siteMetadata.title}`,
        },
        {
          name: 'twitter:description',
          content: ogDescription,
        },
      ].concat(meta)}
    >
      <link rel="alternate" href={`https://www.allpressespresso.com${location.pathname.replace("/ja", "")}"`} hrefLang="en" />
      <link rel="alternate" href={`https://www.allpressespresso.com/ja${location.pathname.replace("/ja", "")}`} hrefLang="ja" />
    </Helmet>
  )
}

export default Seo
