import { Backdrop, Fade, Grid,IconButton, Modal, Paper } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { renderDocumentToReactComponents } from 'components/organisms/renderDocumentToReactComponents'
import Img from "gatsby-image/withIEPolyfill"
import useAPCookies from 'helpers/cookies'
import React, { useEffect, useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { H1 } from 'styles/base'

import { AppStoreBadges } from './appStoreBadges'

const ModalCard = styled(Paper)`
  ${({ theme }): CSSProp => css`
    left: 0;
    bottom: 0;
    position: absolute;
    outline: none;
    font-size: 1.2rem;
    max-width: 100%;
    @media only screen and ${theme.breakpoints.fromSmallScreen} {
      left: 50%;
      top: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
      font-size: 1.8rem;
      width: 75%;
    }

    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      font-size: 2.4rem;
    }

    &.MuiPaper-root {
      background-color: ${theme.colours.secondary};
      border-radius: 0;
    }
  `}
`

const Image = styled(Img)`
  ${({ theme }): CSSProp => css`
    max-width: 100%;
    max-height: 20rem;
    @media only screen and ${theme.breakpoints.fromSmallScreen} {
      max-height: 45rem;
      width: auto;
    }
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      max-height: 55rem;
    }
  `}
`

const ContentWrapper = styled(Grid)`
  ${({ theme }): CSSProp => css`

    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      &.MuiGrid-root {
        flex-wrap: nowrap;
        height: 100%;
      };
    }
  `}
`

const Content = styled(Grid)`
  ${({ theme }): CSSProp => css`
    padding: 1.2rem;

    @media only screen and ${theme.breakpoints.fromSmallScreen} {
      padding: 5rem;
    }
  `}
`

const ImageWrapper = styled(Grid)`
  ${({ theme }): CSSProp => css`
    background-color: ${theme.colours.primaryTint1};
    padding: 1.2rem;

    @media only screen and ${theme.breakpoints.fromMediumScreen} {
      padding: 2.4rem;
    }
  `}
`

const Title = styled(H1)`
  ${({ theme }): CSSProp => css`
    font-size: 1.4rem;
    margin-right: 2.4rem;

    @media only screen and ${theme.breakpoints.fromSmallScreen} {
      font-size: 2.4rem;
    }

    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      font-size: 4.2rem;
    }
  `}
`

const StyledCancelButton = styled.div`
  ${({ theme }): CSSProp => css`
    position: absolute;
    top: 0;
    right: 0;

    .MuiIconButton-root {
      color: ${theme.colours.primary};
    }
  `}
`

type NotificationPropTypes = {
  id: string
  title: string
  body?: string
  image?: {fixed: any}
  showAppStoreDownloads?: boolean
}

export const Notification = (props: NotificationPropTypes): JSX.Element => {
  const [cookies, setCookie] = useAPCookies()
  const { id, title, body, showAppStoreDownloads, image } = props
  const [open, setOpen] = useState(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    // For GDPR don't use cookies unless we are allowed to
    if (
      cookies['can-use-cookies'] === 'true'
    ) {
      // On close of Notification, set a cookie that it was closed
      setCookie(`notification-${id}-closed`, 'true')
    }
    setOpen(false)
  }

  useEffect(() => {
    // Check if notification was already closed for this session then don't display it if it was
    if (
      cookies[`notification-${id}-closed`] !== 'true' ||
      cookies['can-use-cookies'] !== 'true'
    ) {
      setTimeout(() => {
        // Show Notification
        handleOpen()
      }, 3000)
    }
  }, [])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      disableEnforceFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableScrollLock
    >
      <Fade in={open}>
        <ModalCard>
          <ContentWrapper
            container
            direction="row"
            alignItems="stretch"
          >
            <ImageWrapper item xs={4} md={6}>
              <Image objectFit="contain" fixed={{ ...image.fixed }} />
            </ImageWrapper>
            <Content item xs={8} md={6}>
              <Title as="h3">{title}</Title>
              {body && renderDocumentToReactComponents(body)}
              {showAppStoreDownloads && <AppStoreBadges />}
              <StyledCancelButton onClick={handleClose}>
                <IconButton aria-label="delete">
                  <CancelIcon fontSize="large" />
                </IconButton>
              </StyledCancelButton>
            </Content>
          </ContentWrapper>
        </ModalCard>
      </Fade>
    </Modal>
  )
}
