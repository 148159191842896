import { graphql, useStaticQuery } from 'gatsby'

import { getLocalNodes } from '../helpers/locale'
export const useResourceTextData = (key: string, fallBack?: string, multi?: boolean): string => {
  const { allContentfulResourceText } = useStaticQuery(
    graphql`
      query resourceText {
        allContentfulResourceText {
          nodes {
            id
            key
            node_locale
            value {
              value
            }
          }
        }
      }
    `,
  )

  const resourceTextLocal = getLocalNodes(allContentfulResourceText.nodes)
  const filteredResources = resourceTextLocal.filter((r) => {
    return r.key === key
  })

  return multi ? filteredResources : filteredResources[0]?.value?.value || fallBack
}
