import styled from 'styled-components'

import theme from './atoms/theme'

type ContainerProps = {
  type?: 'no-padding'
  narrow?: boolean
}

const Container = styled.div<ContainerProps>`
  max-width: ${(props): string =>
    props.narrow ? '1330px' : theme.layout.maxWidth};
  margin: 0 auto;
  padding: ${(props): string => (props.type === 'no-padding' ? '0' : '0 2rem')};
  width: 100%;
  position: relative;

  @media only screen and ${theme.breakpoints.fromNormalScreen} {
    padding: ${(props): string =>
      props.type === 'no-padding' ? '0' : '0 4rem'};
  }
`

export default Container
