import { graphql, useStaticQuery } from 'gatsby'

import { ContentfulNavigationConnection } from '../types/graphql-types'
export const useFooterNavigationData = (): ContentfulNavigationConnection => {
  const { allContentfulNavigation } = useStaticQuery(
    graphql`
      query footerNavigation {
        allContentfulNavigation(filter: { title: { eq: "Footer" } }) {
          ...ContentfulNavigationNodes
        }
      }
    `
  )

  return allContentfulNavigation
}
