import React from 'react'

export const renderLineBreaks = (str: string): JSX.Element => {
  return str
    ?.split('\n')
    .flatMap((text, i) => [
      i > 0 && <br key={2 * i} />,
      <React.Fragment key={2 * i + 1}>{text}</React.Fragment>,
    ])
}

export const convertLineBreaks = (str: string): string => {
  return str.replace(/\n/g, '<br />')
}
