import { graphql, useStaticQuery } from 'gatsby'
import { ContentfulNotificationConnection } from 'types/graphql-types'

export const useNotificationData = (): ContentfulNotificationConnection => {
  const { allContentfulNotification } = useStaticQuery(
    graphql`
      query notifications {
        allContentfulNotification {
          nodes {
            id
            title
            image {
              title
              fixed(width: 550) {
                base64
                width
                height
                src
                srcSet
              }
            }
            matchingUrlSegment
            showAppStoreDownloads
            node_locale
            body {
              raw
              references {
                ... on Node {
                  ... on ContentfulVideo {
                    contentful_id
                    internal {
                      type
                    }
                    url
                  }
                  ... on ContentfulLink {
                    contentful_id
                    internal {
                      type
                    }
                    link
                    title
                  }
                  ... on ContentfulPage {
                    contentful_id
                    title
                    slug
                    internal {
                      type
                    }
                  }
                  ... on ContentfulArticle {
                    contentful_id
                    title
                    slug
                    internal {
                      type
                    }
                  }
                  ... on ContentfulRegionalLink {
                    contentful_id
                    title
                    nzSlug
                    auSlug
                    ukSlug
                    sgSlug
                    jpSlug
                    internal {
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return allContentfulNotification
}
