import { graphql, useStaticQuery } from 'gatsby'

import { getLocalNodes, getRegionalText } from '../helpers/locale'
export const useRegionalTextData = (
  key: string,
  fallBack?: string
): JSX.Element | JSX.Element[] | string => {
  const { allContentfulRegionalText } = useStaticQuery(
    graphql`
      query regionalText {
        allContentfulRegionalText {
          nodes {
            ...RegionalText
          }
        }
      }
    `
  )

  const regionalTextLocal = getLocalNodes(allContentfulRegionalText.nodes)
  const filteredResources = regionalTextLocal.filter(r => {
    return r.key === key
  })
  return getRegionalText(filteredResources[0]) || fallBack
}
