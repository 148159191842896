declare global {
  interface Window {
      gtag: any
  }
}

interface TriggerEventType {
  event?: string
  action: string
  category: string
  label: string
  value?: string | number | boolean
}

export const triggerEvent = ({ event = 'event', action, category, label, value = null }: TriggerEventType): void => {
  typeof window !== "undefined" && window.gtag && window.gtag(event, action, {
    'event_category' : category,
    'event_label' : label,
    'event_value' : value,
  })
}
