import Icon from 'components/atoms/icon'
import Link from 'components/atoms/link'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import Arrow from 'images/svg/arrow-right.svg'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

const StyledIcon = styled(Icon)`
  ${({ theme }): CSSProp => css`
    margin-right: 0.5rem;
    margin-bottom:0.1rem;
    height: 0.75rem;

    @media only screen and ${theme.breakpoints.fromSmallScreen} {
      margin-right: 0.75rem;
      height: 1rem;
    }

    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      margin-right: 1rem;
      height: 2.1rem;
      width: 2.1rem;
    }
  `}
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`

export const AppStoreBadges = (): JSX.Element => {
  const operatingSystem = getMobileOperatingSystem()

  return (
    <>
      {operatingSystem === 'iOS' && (
        <>
        <StyledLink to="https://apps.apple.com/nz/app/caf%C3%A9-finder/id463949195">
          <StyledIcon className="icon" size="small">
            <Arrow />
          </StyledIcon>
          Apple Store
        </StyledLink>
        </>
      )}
      {operatingSystem === 'Android' && (
        <StyledLink to="https://play.google.com/store/apps/details?id=com.allpress">
          <StyledIcon className="icon" size="small">
            <Arrow />
          </StyledIcon>
          Google Play
        </StyledLink>
      )}
      {operatingSystem !== 'Android' &&
        operatingSystem !== 'iOS' && (
          <>
            <StyledLink to="https://apps.apple.com/nz/app/caf%C3%A9-finder/id463949195">
              <StyledIcon className="icon" size="small">
                <Arrow />
              </StyledIcon>
              Apple Store
            </StyledLink>
            <StyledLink to="https://play.google.com/store/apps/details?id=com.allpress">
              <StyledIcon className="icon" size="small">
                <Arrow />
              </StyledIcon>
              Google Play
            </StyledLink>
          </>
        )}
    </>
  )
}
