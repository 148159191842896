import { useLocation } from "@reach/router" // this helps tracking the location
import theme from 'components/atoms/theme'
import ErrorBoundary from 'components/molecules/error-boundary'
import CookiePopup from 'components/organisms/cookiePopup'
import Footer from 'components/organisms/footer'
import Header from 'components/organisms/header'
import Styles from 'components/Styles'
import { changeLocale, useIntl } from 'gatsby-plugin-intl'
import useAPCookies from 'helpers/cookies'
import { isBrowser } from "helpers/isBrowser"
import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { Notifications } from "./organisms/notifications"

type LayoutPropTypes = {
  children: React.ReactNode
  header?: string
}

const Layout = ({ children, header }: LayoutPropTypes): JSX.Element => {
  const [cookies, setCookie, removeCookie] = useAPCookies()
  const intl = useIntl()
  const location = useLocation()

  useEffect(() => {
    // set original detected region
    if (!cookies.origin) {
      setCookie('detected_origin', process.env.GATSBY_BUILD_LOCALE)
    }

    // trigger GA code
    // @see https://support.google.com/tagmanager/answer/6164990
    if (typeof ga === 'function') {
      ga('gtm8.set', 'dimension2', process.env.GATSBY_BUILD_LOCALE)
    }

    // if current region is jp and ja lang cookie exist and current lang is not ja, redirect to /ja
    if (
      process.env.GATSBY_BUILD_LOCALE === 'jp' &&
      cookies.lang === 'ja' &&
      intl.locale !== 'ja'
    ) {
      changeLocale('ja')
    } else {
      if (intl.locale === 'ja') {
        setCookie('lang', 'ja')
      } else {
        removeCookie('lang')
      }
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Styles />
      <Header type={header} />
      <ErrorBoundary>{children}</ErrorBoundary>
      <Footer />
      { isBrowser && <CookiePopup />}
      <Notifications/>
    </ThemeProvider>
  )
}

export default Layout
