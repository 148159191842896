import React from 'react'
import styled from 'styled-components'

import theme from './theme'

interface IconProps {
  size?: 'small' | 'medium' | 'large' | null
}

const iconSize = (size): string => {
  switch (size) {
    case 'small':
      return theme.icon.smallSize

    case 'medium':
      return theme.icon.mediumSize

    case 'large':
      return theme.icon.largeSize

    default:
      return theme.icon.defaultSize
  }
}

const IconStyles = styled.span<IconProps>`
  width: ${(props: IconProps): string => iconSize(props.size)};
  height: ${(props: IconProps): string => iconSize(props.size)};
  display: inline-block;
  svg {
    height: 100%;
    width: 100%;
    display: block;
  }
`

interface IconPropTypes {
  children: React.ReactNode
  size?: 'small' | 'medium' | 'large' | null
  className?: string
}

const Icon = (props: IconPropTypes): JSX.Element => {
  const { children, size, className } = props
  return (
    <IconStyles className={`icon ${className}`} size={size}>
      {children}
    </IconStyles>
  )
}

export default Icon
