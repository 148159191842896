import React, { Fragment } from 'react'
import styled, { css, CSSProp } from 'styled-components'

const DesktopTable = styled.div`
  ${({ theme }): CSSProp => css`
    display: none;

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      display: block
    }
  `}
`

const MobileTable = styled.div`
  ${({ theme }): CSSProp => css`
    display: none;

    @media only screen and ${theme.breakpoints.toNormalScreen} {
      display: block
    }
  `}
`

type TableBlockType = {
  hasHeader: boolean
  items: [][]
  title: string
}

const Table = styled.table`
  ${({ theme }): CSSProp => css`
    border-spacing: .3rem;
    font-size: ${theme.font.fontSizeDesktop};

    ul, ol {
      padding-left: 2rem;
      margin: 0;
    }
  `}
`

const Blank = styled.td`
  height: 2rem;
`

const TableHeadCell = styled.th`
  ${({ theme }): CSSProp => css`
    background-color: ${theme.colours.lightTint3};
    text-align: left;
    padding: 0.5rem;
  `}
`

const TableBodyCell = styled.td`
  ${({ theme }): CSSProp => css`
    background-color: ${theme.colours.light};
    padding: 0.5rem;

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      background-color: ${theme.colours.secondary};
    }
  `}
`

const TableBlock = (props: TableBlockType ): JSX.Element => {
  const { hasHeader, items, title } = props
  let headerItems = []

  if (hasHeader) {
    headerItems = items[0]
  }

  return (
    <div>
      <MobileTable>
        <Table aria-label={title}>
          <tbody>
            {
              items.map((row, rowIndex) => {
                return row.map((cell, index) => {
                  return hasHeader && rowIndex === 0 ? null : (
                    <Fragment key={index}>
                      <tr>
                        { hasHeader && <TableHeadCell>{headerItems[index]}</TableHeadCell> }
                        <TableBodyCell dangerouslySetInnerHTML={{ __html: cell }}></TableBodyCell>
                      </tr>
                      { row.length === index + 1 && <tr><Blank colSpan={2}/></tr> }
                    </Fragment>
                  )
                })
              })
            }
          </tbody>
        </Table>
      </MobileTable>
      <DesktopTable>
        <Table aria-label={title}>
          {
            hasHeader &&
            <thead>
              <tr>
                {
                  headerItems.map((item: string, index: number): JSX.Element => {
                    return <TableHeadCell key={index}>{item}</TableHeadCell>
                  })
                }
              </tr>
            </thead>
          }
          <tbody>
            {items.map((row: string[], index: number): JSX.Element => {
              return hasHeader && index !== 0 || !hasHeader ? (
                <tr key={index}>
                  {
                    row.map((item: string, index: number) => {
                      return <TableBodyCell key={index} dangerouslySetInnerHTML={{ __html: item }}></TableBodyCell>
                    })
                  }
                </tr>
              ) : null
            })}
          </tbody>
        </Table>
      </DesktopTable>
    </div>
  )
}

export default TableBlock
