import classnames from 'classnames'
import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

import Link from './link'

type StyleButtonProp = {
  alternate?: boolean
}

const StyledSecondaryButton = styled(Link)<StyleButtonProp>`
  ${({ theme }): CSSProp => css`
    cursor: pointer;
    border-radius: 4px;
    padding: 1.5rem 1.8rem 1.35rem;
    display: inline-block;
    background: transparent;
    border: 0.1rem solid ${theme.colours.tertiary};
    font-size: ${theme.font.fontSizeSmall};
    text-align: right;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1.2px;
    transition: background ${theme.animation.defaultLength} ease;
    color: ${theme.colours.tertiary};

    &.outline {
      background: none;
      border: solid ${theme.colours.tertiary} 1px;
      color: ${theme.colours.primary};
    }
    &:hover,
    &.active {
      background: ${theme.colours.tertiary};
      color: ${theme.colours.secondary};
      .icon {
        color: ${theme.colours.secondary};
      }
    }

    &.disabled,
    &:disabled,
    &[disabled] {
      opacity: 0.5;
      cursor: default;
      background: ${theme.colours.tertiary};
    }
  `}
`

const SecondaryButton = ({
  to,
  children,
  alternate,
  ...props
}: {
  to?: string
  children: React.ReactNode
  active?: boolean
  disabled?: boolean
  alternate?: boolean
}): JSX.Element => {
  const buttonClass = classnames('button', {
    active: props.active,
    disabled: props.disabled,
  })

  return to ? (
    <StyledSecondaryButton
      alternate={alternate}
      className={buttonClass}
      to={to}
      {...props}
    >
      {children}
    </StyledSecondaryButton>
  ) : (
    <StyledSecondaryButton
      alternate={alternate}
      className={buttonClass}
      as="button"
      {...props}
    >
      learn more
      {children}
    </StyledSecondaryButton>
  )
}

export default SecondaryButton
