import { renderDocumentToReactComponents } from 'components/organisms/renderDocumentToReactComponents'
import ChevDown from 'images/svg/chev-down.svg'
import React, { useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'

const TitleWrapper = styled.div`
  ${({ theme }): CSSProp => css`
    font-weight: bold;
    display: flex;
    align-items: center;
    cursor: pointer;

    .accordion-title {
      flex-grow: 1;
      margin: 0;
    }

    .arrow {
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform .1s ease-in-out;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    &.active {
      .arrow {
        transform: rotate(180deg);
      }
    }
  `}
`

const DescriptionWrapper = styled.div`
  ${({ theme }): CSSProp => css`
    display: none;

    &.active {
      display: block;
    }
  `}
`

const AccordionItem = styled.div`
  ${({ theme }): CSSProp => css`
    border-bottom: 1px solid ${theme.colours.primary};
  `}
`

const AccordionBlock = (props): JSX.Element => {
  const { items } = props

  const [activeItems, setActiveItems] = useState([])


  const toggleAccordionActive = (index): void => {
    const newActiveItems = activeItems
    if (!newActiveItems.includes(index)) {
      newActiveItems.push(index)
    } else {
      const itemsIndex = newActiveItems.indexOf(index)
      if (itemsIndex > -1) {
        newActiveItems.splice(itemsIndex, 1)
      }
    }

    setActiveItems([...newActiveItems])
  }

  return (
    <>
    {items?.length > 0 &&
    <div>{items.map((item, index) => {
      return (
        <AccordionItem key={index}>
          <TitleWrapper className={`${activeItems.includes(index) && 'active'}`} onClick={(): void => toggleAccordionActive(index)}>
            <h4 className='accordion-title'>{item.title}</h4>
            <div className='arrow'><ChevDown /></div>

          </TitleWrapper>
          <DescriptionWrapper className={`${activeItems.includes(index) && 'active'}`}>{renderDocumentToReactComponents(item.body)}</DescriptionWrapper>
        </AccordionItem>
      )
    })}
    </div> }
    </>
  )
}


export default AccordionBlock
