import { useLocation } from '@reach/router'
import useAPCookies from 'helpers/cookies'
import { regions } from 'helpers/locale'
import React from 'react'

const RegionSwitcher = (): JSX.Element => {
  const [ , setCookie, removeCookie ] = useAPCookies()
  const { pathname } = useLocation()

  const regionSwitch = (region: string): void => {
    // remove origin and lang cookies on region switch
    setCookie('origin', region)
    removeCookie('lang')

    localStorage.removeItem('finderState')

    if (typeof ga === 'function') {
      ga('gtm8.set', 'dimension2', region)
    }
  }

  return (
    <ul>
      { Object.keys(regions).map(id => {
        if (regions[id].languages) {
          return regions[id].languages.map(({ name, prefix }) => (
            <li key={ `${ id }${ name }` }>
              <a
                href={ `${ prefix }${ pathname.replace('/ja/', '/') }` }
                onClick={ (): void => {
                  regionSwitch(id)
                } }
              >
                { regions[id].name } - { name }
              </a>
            </li>
          ))
        } else {
          return (
            <li key={ id }>
              <a
                href={ `${ pathname.replace('/ja/', '/') }` }
                onClick={ (): void => {
                  regionSwitch(id)
                } }
              >
                { regions[id].name }
              </a>
            </li>
          )
        }
      }) }
    </ul>
  )
}

export default RegionSwitcher
