import theme from 'components/atoms/theme'
import styled, { css } from 'styled-components'

export const TextFieldStyles = css`
  background-color: ${theme.colours.secondary};
  border-radius: 2px;
  border: none;
  padding: 1.8rem 2rem;
  width: 100%;
  font-size: 1.4rem;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &.error {
    box-shadow: 0px 0px 0px 1px red;
  }
`

const TextField = styled.input`${TextFieldStyles}`

export default TextField