import React from 'react'

import ChevDown from '../../images/svg/chev-down.svg'
import { ContentfulNavigationItem, Maybe } from '../../types/graphql-types'
import Collapse from '../molecules/collapse'
import NavigationLink from './navigation-link'

export const LinkList = ({ items }: { items: any[] }): JSX.Element => (
  <ul>
    {items.map(menuItem => {
      return (
        <li key={menuItem.id}>
          <NavigationLink menuItem={menuItem} />
        </li>
      )
    })}
  </ul>
)

type NavigationListProps = {
  navigationItems: Maybe<Array<Maybe<ContentfulNavigationItem>>>
  expandable?: boolean
}

const NavigationList = (props: NavigationListProps): JSX.Element => {
  const { navigationItems, expandable } = props

  return (
    <ul>
      {navigationItems?.map(navItem => {
        return (
          <li key={navItem.id}>
            <NavigationLink menuItem={navItem} />
            {navItem.menuItems && (
              <>
                {expandable ? (
                  <Collapse button={<ChevDown />}>
                    <LinkList items={navItem.menuItems} />
                  </Collapse>
                ) : (
                  <>
                    <ChevDown />
                    <LinkList items={navItem.menuItems} />
                  </>
                )}
              </>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default NavigationList
