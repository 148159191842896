const defaultRegion = 'nz'
const defaultLang = 'en-US'

const languages = {
  en: defaultLang,
  ja: 'ja-JP',
}

exports.languages = languages
exports.defaultRegion = defaultRegion
exports.defaultLang = defaultLang
