import { Link as GatsbyLink } from 'gatsby-plugin-intl'
import { isJapanese } from 'helpers/locale'
import React from 'react'

type LinkProps = {
  children: React.ReactNode
  to: string
  activeClassName?: string
  partiallyActive?: boolean
  key?: string
  className?: string
  alternate?: boolean
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName = 'active',
  partiallyActive,
  alternate,
  ...other
}: LinkProps): JSX.Element => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }

  let newLink = to

  // If link is to Japan shopify account and in the Japanese language then insert '/ja' into the url
  if (isJapanese() && to && to.includes('jp.shop.allpressespresso.com') && !to.includes('.com/ja')) {
    const index = to.indexOf('so.com')
    newLink = to.slice(0, index + 6) + '/ja' + to.slice(index + 6)
  }

  // All External links open in a new tab. noopener and noreferrer have been added for security reasons
  return (
    <a href={newLink} rel="noopener nofollow noreferrer" target="_blank" {...other}>
      {children}
    </a>
  )
}
export default Link
