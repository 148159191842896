import styled, { css } from 'styled-components'

import theme from '../components/atoms/theme'

const { breakpoints } = theme

export const H1Style = css`
  font-family: ${theme.font.fontFamilyAlt};
  font-size: 2.2rem;
  line-height: 1.5;
  /* font-weight: ${theme.font.fontWeightNormal}; */
  @media only screen and ${theme.breakpoints.fromNormalScreen} {
    font-size: 2.8rem;
  }
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    font-size: 4.5rem;
    line-height: 1.37;
  }
`
export const H1 = styled.h1`
  ${H1Style}
`

export const WholesaleH1Style = css`
  font-family: ${theme.font.fontFamilyAlt};
  font-size: 2.2rem;
  line-height: 1.5;
  /* font-weight: ${theme.font.fontWeightNormal}; */
  @media only screen and ${theme.breakpoints.fromNormalScreen} {
    font-size: 2.8rem;
  }
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    font-size: 4.5rem;
    line-height: 1.37;
  }
`
export const WholesaleH1 = styled.h1`
  ${WholesaleH1Style}
`

export const H2Style = css`
  font-weight: 900;
  margin-bottom: 1.6rem;
  line-height: 1.2;
  font-size: 2.4rem;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 3.6rem;
  }
`
export const H2 = styled.h2`
  ${H2Style}
`

export const prodCarousselH2Style = css`
  font-size: ${theme.font.fontSizeBodyDefault};
  margin-bottom: 1rem;
  font-family: ${ theme.font.fontFamilyAlt };
  font-weight: ${ theme.font.fontWeightLight };
}
`
export const ProdCarousselH2 = styled.h2`
  ${prodCarousselH2Style}
`

export const WholesaleH2Style = css`
font-size: 2rem;
font-family: ${theme.font.fontFamilyAlt};
line-height: 1;
@media only screen and ${theme.breakpoints.fromNormalScreen} {
  font-size: 2rem;
  position: relative;
  max-width: 60rem;
}
@media only screen and ${theme.breakpoints.fromLargeScreen} {
  font-size: 4rem;
  max-width: 200rem;
  line-height: 1.3;
  padding-left : 10rem;
  padding-right: 10rem;
}
`
export const WholesaleH2 = styled.h2`
  ${WholesaleH2Style}
`

export const H3Style = css`
font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: ${theme.colours.tertiary};
`
export const H3 = styled.h3`
  ${H3Style}
`

export const WholesaleH3Style = css`
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  line-height: 1.5;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.8rem;
  }
`
export const WholesaleH3 = styled.h3`
  ${WholesaleH3Style}

  /* Apply underline hover effect only if parent has .has-link */
  .has-link & {
    &:hover {
      text-decoration: underline;
      text-underline-position: under;
    }
  }
`

export const SubheadingStyle = css`
font-size: 1.2rem;
text-transform: uppercase;
letter-spacing: 1.2px;
font-weight: ${theme.font.fontWeightBold};
color: ${theme.colours.tertiary};
`
export const Subheadings = styled.p`
  ${SubheadingStyle}
`



export const H4Style = css`
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
  line-height: 1.5;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.8rem;
  }
`
export const H4 = styled.h4`
  ${H4Style}
`

export const WholesaleH4Style = css`
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: ${theme.colours.tertiary};
`
export const WholesaleH4 = styled.h4`
  ${WholesaleH4Style}
`

export const WholesaleBlockQuoteStyle = css`
  font-size: 1.7rem;
  line-height: 1.7;
  margin-top: 2rem;
  font-family: ${theme.font.fontFamilyAlt};
  font-weight: ${theme.font.fontWeightBold};

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    font-size: 1.7rem;
  }
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    font-size: 2.5rem;
    
  }
}
`
export const WholesaleBlockQuote = styled.p`
  ${WholesaleBlockQuoteStyle}
`

export const leadingParagraphStyle = css`
font-size: 1.7rem;
line-height: 1.7;
margin-top: 2rem;
font-weight: ${theme.font.fontWeightLight};
font-family: ${theme.font.fontFamilyAlt};
@media only screen and ${theme.breakpoints.fromMediumScreen} {
  font-size: 1.7rem;
}
@media only screen and ${theme.breakpoints.fromLargeScreen} {
  font-size: 2.5rem;
  
}
}
`
export const LeadingParagraph = styled.h2`
  ${leadingParagraphStyle}
`

export const WholesalePStyle = css`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 100;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.6rem;
  }
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    font-size: 1.8rem;
    
  }
`
export const WholesaleP = styled.p`
  ${WholesalePStyle}
`

export const WholesaleH5Style = css`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 100;
  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.1rem;
  }
`
export const WholesaleH5 = styled.h5`
  ${WholesaleH5Style}
`

export const NavTitlesStyle = css`
  color: ${theme.colours.tertiary};
  text-transform: uppercase;
  font-size: ${theme.font.fontSizeSmall};
  letter-spacing: 1.5px;
  font-weight: normal;
  margin-bottom: 2rem;
}
`
export const NavTitles = styled.p`
  ${NavTitlesStyle}
`

export const H5Style = css`
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
  line-height: 1.5;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.6rem;
  }
`
export const H5 = styled.h5`
  ${H4Style}
`

export const H6Style = css`
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1.6rem;
  text-transform: uppercase;
  line-height: 1.4;

  @media only screen and ${breakpoints.fromNormalScreen} {
    font-size: 1.4rem;
  }
`
export const H6 = styled.h6`
  ${H6Style}
`
