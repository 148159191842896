import { graphql, useStaticQuery } from 'gatsby'
import { ContentfulNavigationConnection } from 'types/graphql-types'

export const useMainMenuNavigationData = (): ContentfulNavigationConnection => {
  const { allContentfulNavigation } = useStaticQuery(
    graphql`
      query mainmenuNavigation {
        allContentfulNavigation(filter: { title: { eq: "Main Menu" } }) {
          ...ContentfulNavigationNodes
        }
      }
    `
  )

  return allContentfulNavigation
}
