import useAPCookies from 'helpers/cookies'
import { isBrowser } from 'helpers/isBrowser'
import { useResourceTextData } from 'hooks/useResourceTextData'
import { useEffect } from 'react'

declare global {
  interface Window {
    gtag: any
    dataLayer: any
    CookieControl: any
  }
}

const CookiePopup = (): JSX.Element => {
  const [, setCookie] = useAPCookies()

  const title = useResourceTextData('cookie.title', 'This site uses cookies to store information on your computer.')
  const accept = useResourceTextData('cookie.accept', 'I Accept')
  const reject = useResourceTextData('cookie.reject', 'I Do Not Accept')
  const settings = useResourceTextData('cookie.settings', 'Settings')
  const intro = useResourceTextData('cookie.intro', 'Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.')
  const notifyTitle = useResourceTextData('cookie.notifyTitle', 'Your choice regarding cookies on this site')
  const notifyDescription = useResourceTextData('cookie.notifyDescription', 'Cookies go really well with coffee. We use cookies to optimise website functionality and ensure the best possible experience on our site.')
  const necessaryTitle = useResourceTextData('cookie.necessaryTitle', 'Necessary Cookies')
  const necessaryDescription = useResourceTextData('cookie.necessaryDescription', 'Necessary cookies enable core functionality such as page navigation and access to secure areas. The website cannot function properly without these cookies, and can only be disabled by changing your browser preferences.')
  const analyticsTitle = useResourceTextData('cookie.analyticsTitle', 'Analytics')
  const analyticsDescription = useResourceTextData('cookie.analyticsDescription', 'Analytical cookies help us to improve our website by collecting and reporting information on its usage.')
  const privacyName = useResourceTextData('cookie.privacyName', 'Privacy Policy')
  const privacyDescription = useResourceTextData('cookie.privacyDescription', 'For more information visit our')
  const privacyLink = useResourceTextData('cookie.privacyLink', 'https://uk.shop.allpressespresso.com/policies/privacy-policy')

  window.dataLayer = window.dataLayer || []
  // https://stackoverflow.com/questions/69185534/google-tags-manager-dont-load-any-cookies
  function gtag(...args): void {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://cc.cdn.civiccomputing.com/9/cookieControl-9.5.min.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (isBrowser && window.CookieControl) {
        const cookieControlConfig = {
          apiKey: '82cc6ba5228552cfdec2b2e6fc9ee27f8c207811',
          product: 'PRO',
          necessaryCookies: [
            'detected_origin',
            'origin',
            'lang',
            'can-use-cookies',
            '_ab',
            '_secure_session_id',
            '_shopify_country',
            '_shopify_m',
            '_shopify_tm',
            '_shopify_tw',
            '_storefront_u',
            '_tracking_consent',
            'c',
            'cart',
            'cart_currency',
            'cart_sig',
            'cart_ts',
            'cart_ver',
            'checkout',
            'checkout_token',
            'dynamic_checkout_shown_on_cart',
            'hide_shopify_pay_for_checkout',
            'keep_alive',
            'master_device_id',
            'previous_step',
            'remember_me',
            'secure_customer_sig',
            'shopify_pay',
            'shopify_pay_redirect',
            'storefront_digest',
            'tracked_start_checkout',
            '_Brochure_session',
            'signed_in',
            'user',
          ],
          optionalCookies: [
            {
              name: 'analytics',
              label: analyticsTitle,
              description: analyticsDescription,
              cookies: [
                '__h',
                'notification-*',
                'IDE',
                'NID',
                'KL_FORMS_MODAL',
                'PHPSESSID',
                '__cf_bm',
                '__kla_id',
                '_dc_gtm_*',
                '_fbp',
                '_ga',
                '_ga*',
                '_gat',
                '_gat_UA-*',
                '_gcl_au',
                '_gid',
                '_hjAbsoluteSessionInProgress',
                '_hjFirstSeen',
                '_hjid',
                '_landing_page',
                '_orig_referrer',
                '_s',
                '__session_id',
                '_shopify_d',
                '_shopify_uniq',
                '_shopify_s',
                '_shopify_sa_p',
                '_shopify_sa_t',
                '_shopify_y',
                '_y',
                '_shopify_evids',
                'ab_test_*',
                'cart_sig',
                'ki_r',
                'ki_t',
                '__utma',
                '__utmt',
                '__utmb',
                '__utmc',
                '__utmz',
                '__utmv',
                'ahoy_visitor',
                'fr',
                'secure_customer_sig',
              ],
              onAccept: (): void => {
                setCookie('can-use-cookies', 'true')

                if (isBrowser && window.dataLayer) {
                  gtag('consent', 'update', {
                    'ad_storage': 'granted',
                    'analytics_storage': 'granted',
                  })
                  window.dataLayer.push({
                    event: 'analytics_consent_given',
                  })
                }
              },
              onRevoke: (): void => {
                setCookie('can-use-cookies', 'false')
                if (isBrowser && window.dataLayer) {
                  gtag('consent', 'update', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied',
                  })
                  window.dataLayer.push({
                    event: 'analytics_consent_given',
                  })
                }
              },
            },
          ],
          text: {
            title: title,
            accept: accept,
            reject: reject,
            settings: settings,
            acceptSettings: accept,
            rejectSettings: reject,
            intro: intro,
            notifyTitle: notifyTitle,
            notifyDescription: notifyDescription,
            necessaryTitle: necessaryTitle,
            necessaryDescription: necessaryDescription,
          },
          initialState: 'notify',
          notifyDismissButton: false,
          position: 'left',
          theme: 'dark',
          statement: {
            description: privacyDescription,
            name: privacyName,
            url: privacyLink,
            updated: '26/01/2018',
          },
          branding: {
            fontFamily: 'adelle-sans,sans-serif',
            fontSize: '17px',
            backgroundColor: '#27150f',
            acceptBackground: '#b2936f',
            rejectBackground: '#b2936f',
            toggleColor: '#27150f',
            toggleBackground: '#b2936f',
            removeAbout: true,
          },
        }
        window.CookieControl.load(cookieControlConfig)
      }
    })

    return (): void => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default CookiePopup
