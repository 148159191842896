import Button from 'components/atoms/button'
import TextField from 'components/atoms/textField'
import { Formik } from 'formik'
import { triggerEvent } from 'helpers/tracking'
import { useResourceTextData } from 'hooks/useResourceTextData'
import React, { useState } from 'react'
import styled, { css, CSSProp } from 'styled-components'
import * as Yup from 'yup'

const StyledNewsletterForm = styled.div`
  ${({ theme }): CSSProp => css`
    font-size: 1.3rem;
    margin-bottom: 2.5rem;

    .copy {
      max-width: 30rem;
      margin-bottom: 1rem;
    }

    form {
      display: flex;
      max-width: 45rem;
    }
    input {
      background-color: white;
      flex: 1;
      padding: 0.8rem 1.2rem;
      border-radius: 2px 0 0 2px;
    }
    button {
      white-space: nowrap;
      border-radius: 0 2px 2px 0;
    }
    .msg {
      margin-top: 1rem;
    }
  `}
`

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
})

const NewsletterForm = (): JSX.Element => {
  const statuses = { SUCCESS: 'success', FAILURE: 'failure' }
  const [status, setStatus] = useState('')

  const successMsg = useResourceTextData(
    'footer.signUpSuccess',
    "Thanks! We'll be in touch."
  )
  const failureMsg = useResourceTextData(
    'footer.signUpFailure',
    'Sorry! There was an error submitting the form.'
  )
  return (
    <StyledNewsletterForm>
      <div className="copy">
        {useResourceTextData('footer.signUpText', 'Stay up to date')}
      </div>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const res = await fetch(
              `${process.env.GATSBY_API_DOMAIN}/${process.env.GATSBY_BUILD_LOCALE}/klaviyo-subscribe`,
              { method: 'POST', body: JSON.stringify(values) }
            )
            // const resJson = await res.json()
            if (res.ok) {
              triggerEvent({
                action: 'completed form',
                category: 'Form submit',
                label: 'newsletter-signup-submitted',
              })
              setStatus(statuses.SUCCESS)
            } else {
              triggerEvent({
                action: 'error form',
                category: 'Form submit',
                label: 'newsletter-signup-submission-error',
              })
              setStatus(statuses.FAILURE)
            }
          } catch (e) {
            console.log(e)
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            {status !== statuses.SUCCESS && (
              <form onSubmit={handleSubmit}>
                <TextField
                  type="text"
                  name="email"
                  placeholder={useResourceTextData(
                    'footer.formFieldEmail',
                    'Your email'
                  )}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={errors.email && 'error'}
                />
                <Button type="submit" disabled={isSubmitting} noIcon>
                  {useResourceTextData('footer.signupButtonText', 'Sign up')}
                </Button>
              </form>
            )}
          </>
        )}
      </Formik>
      {status == statuses.SUCCESS && <div className="msg">{successMsg}</div>}
      {status == statuses.FAILURE && <div className="msg">{failureMsg}</div>}
    </StyledNewsletterForm>
  )
}

export default NewsletterForm
