import { useLocation } from '@reach/router' // this helps tracking the location
import { isBrowser } from 'helpers/isBrowser'
import { triggerEvent } from 'helpers/tracking'
import React, { useEffect } from 'react'

interface KlaviyoEvent extends Event {
  detail?: {
    type?: string
    formId?: string
  }
}

export const KlaviyoBlock = ({ id }: { id?: string }): JSX.Element => {
  const location = useLocation()
  useEffect(() => {
    if(isBrowser && !location.hash) {
      window.location.hash = '#loaded'
      window.location.reload()
    }
    isBrowser && window.scrollTo(0, 0)
    if (isBrowser) {
      window.addEventListener("klaviyoForms", (e: KlaviyoEvent) => {
        if (e.detail.type == 'open' || e.detail.type == 'embedOpen') {
          triggerEvent({
            action: 'form_open',
            category: 'Klaviyo form',
            label: e.detail.formId,
          })
        }
        if (e.detail.type == 'submit') {
          triggerEvent({
            action: 'form_submit',
            category: 'Klaviyo form',
            label: e.detail.formId,
          })
        }
        if (e.detail.type == 'close') {
          triggerEvent({
            action: 'form_close',
            category: 'Klaviyo form',
            label: e.detail.formId,
          })
        }
      })
    }
  }, [])

  return (
    <>
      { id && <div className={`klaviyo-form-${id}`}/> }
    </>
  )
}
