import { graphql, useStaticQuery } from 'gatsby'

import { ContentfulNavigationConnection } from '../types/graphql-types'
export const useCopyrightNavigationData = (): ContentfulNavigationConnection => {
  const { allContentfulNavigation } = useStaticQuery(
    graphql`
      query copyrightNavigation {
        allContentfulNavigation(filter: { title: { eq: "Copyright" } }) {
          ...ContentfulNavigationNodes
        }
      }
    `
  )

  return allContentfulNavigation
}
