import { useCookies } from 'react-cookie'
import { Cookie, CookieSetOptions } from 'universal-cookie'

const useAPCookies = (): [ { [name: string]: any }, (name: string, value: Cookie, options?: CookieSetOptions) => void, (name) => void ] => {
  const [ cookies, setCookie, removeCookie ] = useCookies([ 'allpress' ])

  const setAPCookie = (name, value, options = {}) => {
    // Clear all domains / paths except root
    const rootDomain = window.location.hostname.replace('www.', '')
    const rootPath = '/'
    const domains = new Set([
      window.location.hostname,
      rootDomain,
    ])
    const paths = new Set([
      window.location.pathname,
      rootPath,
    ])
    domains.forEach(domain => {
      paths.forEach(path => {
        if (path !== rootPath || domain !== rootDomain) {
          removeCookie(name, { path, domain })
        }
      })
    })

    setCookie(name, value, {
      ...options,
      path: rootPath,
      domain: rootDomain
    })
  }

  const removeAPCookie = (name) => {
    // Remove for all domains / paths
    const domains = new Set([
      window.location.hostname,
      window.location.hostname.replace('www.', ''),
    ])
    const paths = new Set([
      window.location.pathname,
      '/',
    ])
    domains.forEach(domain => {
      paths.forEach(path => {
        removeCookie(name, { path, domain })
      })
    })
  }

  return [ cookies, setAPCookie, removeAPCookie ]
}

export default useAPCookies
