import * as Sentry from '@sentry/browser'
import React, { Component, ErrorInfo, ReactNode } from 'react'

export interface ErrorBoundaryState {
  hasError?: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryState, {}> {
  public state: ErrorBoundaryState
  constructor(props: object) {
    super(props)
    this.state = { hasError: false }
  }
  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ hasError: true })
    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key as keyof React.ErrorInfo])
      })
    })
    Sentry.captureException(error)
  }
  public render(): ReactNode {
    const { hasError } = this.state
    if (hasError) {
      return (
        <>
          <h1>We&apos;re sorry</h1>
          <p>
            Something went wrong.
          </p>
        </>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
