import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { EmbeddedEntry } from 'components/atoms/embeddedEntry'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { renderLineBreaks } from 'helpers/text'
import React, { ReactNode } from 'react'

const renderEntry = (node, children): JSX.Element => {
  if (node.data?.target?.internal?.type) {
    return (
      <EmbeddedEntry
        fields={node.data.target}
        type={node.data.target.internal.type}
      >
        {children}
      </EmbeddedEntry>
    )
  } else {
    // silent fail
    return null
  }
}

const renderAsset = (node): JSX.Element => {
  return node.data.target ? (
    <EmbeddedEntry
      fields={node.data.target}
      type={node.data.target?.__typename}
    />
  ) : null
}

const nodeRenderer = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node, children): JSX.Element =>
      renderEntry(node, children),
    [BLOCKS.EMBEDDED_ASSET]: (node): JSX.Element => renderAsset(node),
    [INLINES.EMBEDDED_ENTRY]: (node, children): JSX.Element =>
      renderEntry(node, children),
    [INLINES.ENTRY_HYPERLINK]: (node, children): JSX.Element =>
      renderEntry(node, children),
  },
  renderText: (textToRender): string => renderLineBreaks(textToRender),
}

export const renderDocumentToReactComponents = (document): ReactNode => {
  return renderRichText(document, nodeRenderer)
}
