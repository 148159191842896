import { useLocation } from '@reach/router' // this helps tracking the location
import { Notification } from 'components/molecules/notification'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { getLocalNodes } from 'helpers/locale'
import { matchRule } from 'helpers/matchRule'
import { useNotificationData } from 'hooks/useNotificationData'
import React from 'react'

export const Notifications = (): JSX.Element => {
  const notifications = useNotificationData()
  const location = useLocation()
  const notificationsLocal = getLocalNodes(notifications.nodes)

  if (getMobileOperatingSystem() !== 'unknown' && notificationsLocal && notificationsLocal.length > 0) {
    // Loop through notifications that should be display at the current matching slug
    return notificationsLocal
      .filter(notification => {
        return matchRule(location.pathname, notification.matchingUrlSegment)
      })
      .map(notification => {
        return (
          <Notification
            key={notification.id}
            title={notification.title}
            body={notification.body}
            id={notification.id}
            image={notification.image}
            showAppStoreDownloads={notification.showAppStoreDownloads}
          />
        )
      })
  } else {
    return null
  }
}
