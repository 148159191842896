import Icon from 'components/atoms/icon'
import Img, { FluidObject } from 'gatsby-image'
import Arrow from 'images/svg/arrow-right.svg'
import React, { Fragment, useRef } from 'react'
import Slider from 'react-slick'
import styled, { css, CSSProp } from 'styled-components'

const minSliderImages = 4

const StyledImagesBlock = styled.div<{
  slider: boolean
  singleImageLayout: string
}>`
  ${({ theme, slider, singleImageLayout }): CSSProp => css`
    margin: 5rem 0;
    display: ${slider ? 'block' : 'grid'};
    grid-gap: 1rem;

    ${slider &&
      css`
        position: relative;
        @media only screen and ${theme.breakpoints.fromMediumScreen} {
          &:before {
            content: '';
            background: #f9f3ee;
            background: linear-gradient(
              90deg,
              #f9f3ee 0%,
              #f9f3ee 0%,
              #f9f3ee 99%,
              rgba(239, 231, 221, 0) 100%
            );
            position: absolute;
            z-index: 1;
            top: 0;
            right: 100%;
            height: 100%;
            width: 100vw;
          }
        }
      `}

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      grid-auto-flow: column;
      margin: 5.5rem -7rem;
    }
    @media only screen and ${theme.breakpoints.fromLargeScreen} {
      margin: 7rem 0;
    }
    @media only screen and (min-width: 1520px) {
      margin: 7rem -16rem;
    }
    .gatsby-image-wrapper {
      max-height: 700px;
      ${singleImageLayout === 'Small' &&
        css`
          max-width: 33%;
          margin-left: auto;
          margin-right: auto;
        `}
      ${singleImageLayout === 'Medium' &&
        css`
          max-width: 50%;
          margin-left: auto;
          margin-right: auto;
        `}
    }
  `}
`

const Caption = styled.div`
  ${({ theme }): CSSProp => css`
    font-size: ${theme.font.fontSizeMid};
    font-style: italic;
    margin: 0.5rem 2rem 0;

    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      margin: 0.5rem 0 0;
    }
`}
`

const SliderRootWrapper = styled.div`
  ${({ theme }): CSSProp => css`
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;

    .fade {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 30rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        background: linear-gradient(to left, #f9f3ee, rgba(0, 0, 0, 0));
      }

      button {
        pointer-events: all;
        transition: ${theme.animation.defaultLength} ease;
        opacity: 0;
        background-color: ${theme.colours.tertiary};
        color: ${theme.colours.light};
        border: none;
        cursor: pointer;
        outline: none;
        width: 8rem;
        height: 10rem;
        @media only screen and ${theme.breakpoints.toLargeScreen} {
          opacity: 1;
          height: 7rem;
          width: 6rem;
        }
      }
    }

    &:hover .fade button {
      @media only screen and ${theme.breakpoints
          .fromLargeScreen} and (pointer: fine) {
        opacity: 1;
      }
    }
    .slick-list {
      overflow: visible;
    }
    .slick-slide {
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        padding: 0 4rem 0 0;
      }
    }
  `}
`

const ImagesBlock = ({
  items,
  singleImageLayout,
}: {
  items: [{ fluid: FluidObject; title?: string; description?: string }]
  singleImageLayout: string
}): JSX.Element => {
  const slider = useRef<Slider>()
  const nextSlide = (): void => {
    slider?.current?.slickNext()
  }

  const SliderWrapper = ({ children }): JSX.Element => (
    <Slider ref={slider} arrows={false}>
      {children}
    </Slider>
  )

  const isSlider = items.length >= minSliderImages

  const WrapperComponent = isSlider ? SliderWrapper : Fragment
  return (
    <SliderRootWrapper>
      <div className="content">
        <StyledImagesBlock
          slider={isSlider}
          singleImageLayout={items.length === 1 ? singleImageLayout : null}
        >
          <WrapperComponent>
            {items.map(item => {
              return (
                <div key={item.fluid.src}>
                  <Img
                    alt={item.title ? item.title: null}
                    fluid={{
                      ...item.fluid,
                      aspectRatio: isSlider ? 1.33 : item.fluid.aspectRatio,
                    }}
                    imgStyle={{
                      objectFit: items.length === 1 ? 'contain' : 'cover',
                    }}
                  />
                  { item.description && <Caption>{item.description}</Caption> }
                </div>
              )
            })}
          </WrapperComponent>
        </StyledImagesBlock>
      </div>
      {isSlider && (
        <div className="fade">
          <button onClick={(): void => nextSlide()}>
            <Icon>
              <Arrow />
            </Icon>
          </button>
        </div>
      )}
    </SliderRootWrapper>
  )
}

export default ImagesBlock
